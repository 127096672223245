<template>
    <div>
        <Echart :options="options" id="centreLeft1Chart" height="250px" width="420px"></Echart>
    </div>
</template>
  
<script>
import echarts from 'echarts'
import Echart from '@/common/echart'
export default {
    data() {
        return {
            options: {},
        };
    },
    components: {
        Echart,
    },
    props: {
        cdata: {
            type: Object,
            default: () => ({})
        },
    },
    watch: {
        cdata: {
            handler(newData) {
                this.options = {
                    legend: {
                        right: 0
                    },
                    tooltip: {},
                    dataset: {
                        dimensions: newData.dimensions,
                        source: newData.source
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: {
                            interval: 0
                        }
                    },
                    yAxis: {
                        name: '人',
                        splitLine: {    // gird 区域中的分割线
                            show: true,   // 是否显示
                            lineStyle: {
                                color: 'rgba(143, 171, 191, 1)',
                                width: 0.7,
                                type: 'solid'   // dashed
                            }
                        },
                        axisLine: {
                            show: false
                        },
                        type: 'value',
                        scale: true,
                        max: 300,
                        min: 0,
                        splitNumber: 3,
                        boundaryGap: [0.2, 0.2]
                    },
                    series: [
                        {
                            type: 'bar',
                            barWidth: 10,
                            color: {
                                type: 'linear',
                                x: 0, //右
                                y: 0, //下
                                x2: 0, //左
                                y2: 1, //上
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: 'rgba(31, 89, 89, 0.25)'
                                        // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(51, 204, 204, 1)' // 100% 处的颜色
                                    }
                                ]
                            },
                            itemStyle: {
                                normal: {
                                    // 边框渐变
                                    borderColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: '#00f0fd'
                                    }, {
                                        offset: 0.8,
                                        color: '#00f0fd'
                                    }], false)
                                },
                            },
                        },

                    ]
                }
            },
            immediate: true,
            deep: true
        }
    }
};
</script>
  
<style lang="scss" scoped></style>