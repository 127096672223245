<template>
  <div class="bigbox">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data() {
    return {
      cdata: {
        dimensions: ['product', '人员数量',],
        source: [
          { product: '嫁接', 人员数量: 200,},
          { product: '翻犁', 人员数量: 110,},
          { product: '代育插秧', 人员数量: 390,},
          { product: '旋耕', 人员数量: 200,},
          { product: '灌溉', 人员数量: 380,},
          { product: '机械施肥', 人员数量: 360,},
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  // .bigbox{
  //   margin-top: 10px;
  // }
</style>