<template>
  <div id="centerLeft1">
    <div class="title">
      <img
        src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-right-icon1.png"
        alt=""
      />
      <span class="text">农人画像</span>
    </div>
    <div class="quantity">
      <img
        src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-left-icon1.png"
        alt=""
        class="quantity-img"
      />
      <div class="characterInfo">
        <div class="item">
          <span class="lable">姓名：</span>
          <span class="value">{{ characterInfoData.name }}</span>
        </div>
        <div class="item">
          <span class="lable">性别：</span>
          <span class="value">{{ characterInfoData.sex }}</span>
        </div>
        <div class="item">
          <span class="lable">学历：</span>
          <span class="value">{{ characterInfoData.educational }}</span>
        </div>
        <div class="item">
          <span class="lable">身份：</span>
          <span class="value identitybox">{{
            characterInfoData.identity
          }}</span>
        </div>
        <div class="item">
          <span class="lable">身份证号码：</span>
          <span class="value">{{ characterInfoData.idCard }}</span>
        </div>
        <div class="item">
          <span class="lable">户籍地址：</span>
          <span class="value">{{ characterInfoData.address }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      characterInfoData: {
        name: "赵国强",
        sex: "男",
        educational: "高中",
        identity: "农业中高级人才",
        idCard: "610***********1203",
        address: "陕西省杨陵区***村苹果园",
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 245px;
  margin-bottom: 10px;

  .title {
    width: 100%;
    height: 32px;
    // background-image: url("../../assets/title.png");
    background: linear-gradient(270deg, rgba(31, 67, 102, 0) 0%, rgba(31, 67, 102, .25) 100%);
    // background-size: cover;
    display: flex;
    align-items: center;
    padding-left: 6px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }
    .text {
      // margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .quantity {
    
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
    background-image: url("https://nonglian.oss-cn-shanghai.aliyuncs.com/pag2-right-bg1.png");
    background-size: 100% 100%;
    padding-bottom: 24px;

    .quantity-img {
      width: 163px;
      height: 187px;
    }

    .characterInfo{
      width: 100%;
      padding: 0 24px;
      
    }
    .item {
      margin-bottom: 7px;
      .lable {
        font-size: 12px;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
        opacity: 0.5;
      }

      .value {
        font-size: 12px;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
        opacity: 1;
      }

      .identitybox {
        display: inline-block;
        width: 121px;
        height: 26px;
        background: linear-gradient(
          180deg,
          rgba(234, 241, 248, 0.1) 0%,
          rgba(208, 222, 238, 0.1) 100%
        );
        border: 0.5px solid rgba(21, 154, 255, 1);
        text-align: center;
        line-height: 26px;
        color:rgba(79, 240, 255, 1);
      }
    }
  }
}
</style>
