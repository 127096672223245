<template>
  <div id="centerRight1">
    <div class="title">
      <img src="../../assets/img/title-icon1.png" alt="" class="title-icon">
      <span class="text">紧缺农服预警</span>
    </div>
    <!-- <CenterRight1Chart/> -->
    <!-- <div class="content">
      <div class="item" v-for="(item, index) in itemData" :key="index">
        <div class="item-num">{{ item.num }}</div>
        <img :src="item.url" alt="" class="item-img">
      </div>
    </div>
    <div class="text-content">
      <div class="text-item" v-for="(item, index) in itemData" :key="index">
        <div class="item-text">{{ item.text }}</div>
      </div>
    </div> -->
    <CenterRight2Chart/>
  </div>
</template>

<script>
import CenterRight2Chart from '@/components/echart/page2CenterRight/centerRight2Chart'
export default {
  components:{
    CenterRight2Chart
  },
  data() {
    return {
      itemData: [
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-right2-icon1.png',
          text: '翻犁',
          num: '58%'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-right2-icon3.png',
          text: '旋耕',
          num: '44%'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-right2-icon2.png',
          text: '代育插秧',
          num: '75%'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-right2-icon4.png',
          text: '嫁接',
          num: '22%'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
// $box-height: 410px;
// $box-width: 300px;
#centerRight1 {
  width: 400px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/img/title-bg.png');
    background-size: cover;
    border-top: 1.5px solid rgba(91, 116, 140, 1);
    display: flex;
    align-items: center;

    .text {
      margin-left: 12px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  // .content {
  //   display: flex;
  //   justify-content: space-between;
  //   margin-top: 20px;
  //   border-bottom: 2px solid rgba(179, 223, 255, 0.5);
    
  // }

  // .text-content {
  //   display: flex;
  //   justify-content: space-between;
  //   margin-top: 9px;
  //   margin-bottom: 19px;

  //   .text-item {
  //     width: 92px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     font-size: 12px;
  //     font-weight: 500;
  //     letter-spacing: 1.71px;
  //   }
  // }

  // .item {
  //   width: 92px;
  //   height: 129px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: flex-end;
  //   background: rgba(77, 89, 102, 0.1);

  //   .item-img {
  //     opacity: 1;
  //   }

  //   .item-num {
  //     font-size: 16px;
  //     font-weight: 900;
  //     letter-spacing: 1px;
  //     color: rgba(208, 222, 238, 1);
  //     margin-bottom: 6px;
  //   }
  // }
}
</style>
