<template>
  <div id="centerLeft1">
    <div class="title">
      <!-- <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-right-icon1.png" alt=""> -->
      <span class="text">信息服务概况</span>
    </div>
    <div class="title-border">
      <div class="border1"></div>
      <div class="border2"></div>
      <div class="border3"></div>
      <div class="border4"></div>
    </div>
    <dv-scroll-board :config="config" class="scroll-board" style="width:100%;height:230px" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        rowNum: 6,
        columnWidth: [180, 60,150],
        headerBGC: 'transparent',
        oddRowBGC: 'rgba(61, 85, 102, 0.16)',
        evenRowBGC: 'transparent',
        header: ['用户发布内容', '类型','匹配情况'],
        data: [
          ['200公斤豆角出售', '供应','已匹配208条'],
          ['猕猴桃采摘', '劳务','<span style="color:rgba(242, 36, 36, 1);">未匹配</span>'],
          ['提供服务专业嫁接', '服务','已匹配62条'],
          ['10亩苹果园接受预售', '求购','已匹配86条'],
          ['果树修剪嫁接', '劳务','<span style="color:rgba(242, 36, 36, 1);">未匹配</span>'],
          ['各种苗木幼苗供应', '供应','<span style="color:rgba(242, 36, 36, 1);">未匹配</span>'],
          ['果树修剪嫁接', '服务','已匹配147条'],
          ['猕猴桃采摘', '劳务','<span style="color:rgba(242, 36, 36, 1);">未匹配</span>'],
          ['提供服务专业嫁接', '服务','已匹配62条'],
          ['10亩苹果园接受预售', '求购','已匹配86条'],
        ]
      }
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;
  // margin-bottom: 20px;
  padding: 0px 0px 14px;

  .title {
    width: 100%;
    height: 29px;
    // background-image: url("../../assets/title.png");
    // background-size: cover;
    display: flex;
    align-items: center;
    padding-left: 16px;

    &::after {
      background: linear-gradient(270deg, rgba(31, 67, 102, 0) 0%, rgba(31, 67, 102, 1) 100%);
      opacity: 0.25;
      z-index: -99;
    }

    .text {
      // margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
      text-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.56px;
      line-height: 20.27px;
      color: rgba(172, 201, 230, 1);
      z-index: 99;
    }
  }

  .title-border {
    display: flex;
    margin-bottom: 12px;

    .border1 {
      width: 8px;
      height: 0;
      border: 2px solid rgba(82, 153, 204, 1);
      margin-right: 6px;
    }

    .border2 {
      width: 8px;
      height: 0px;
      opacity: 1;
      border: 2px solid rgba(41, 77, 102, 1);
      margin-right: 5px;
    }

    .border3 {
      width: 8px;
      height: 0px;
      opacity: 1;
      border: 2px solid rgba(41, 77, 102, 1);
      margin-right: 8px;
    }

    .border4 {
      width: 357.31px;
      height: 0px;
      opacity: 1;
      border: 1px solid rgba(29, 49, 64, 1);
    }
  }

  ::v-deep .scroll-board .header .header-item {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    color: rgba(51, 204, 204, 1);
    // text-align: center;
  }
}
</style>
